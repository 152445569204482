import HomeCarousel from "./HomeCarousel";
//import HomeCards from './HomeCards';

function Home() {
  return (
    <div>
        <HomeCarousel />
        {/* <HomeCards /> */}
    </div>
  )
};

export default Home;